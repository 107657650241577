@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

.para-color{
  font-family: 'Inter' !important;
  font-size: 15.3px;
  line-height: 1.6;
  color: #333;
}

#head {
  font-family: "EB Garamond", serif;
  font-size: 35px;
  text-shadow: 3px 4px 5px black;
}

.para-color2{
  font-family: 'Inter' !important;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}
.para-color3{
  font-family: 'Inter' !important;
  font-size: 17px;
  line-height: 1.9;
  color: #413f3f;
}


.para-color1{
  font-family: 'Inter' !important;
  font-size: 15.4px;
  line-height: 1.7;
  color: #fff;
}

.h2-color{
  font-family: 'Inter' !important;

}

.green-clr{
  color: #C1E74B !important;
}

.card{
  border: 0px;
}
.center1{
  display: grid;
  place-content: center;
}

@media (max-width: 992px) {
    .offcanvas-body {
    background-color: #255946 !important;
    overflow-y: visible;
    height: 1000px !important;
  }
}

.para-butn{
  font-family: 'Inter' !important;
  font-size: 13.3px;
  line-height: 1.6;
  color: #333;
}

.aos {
  overflow: hidden;
}

.bg {
  background: #255946;
  background-image: url('images/video-bg.png');
  background-size: 100%;
  background-attachment: fixed;
}

#off{
  background-color: #255946 !important;
}
.buggy{
  background: #255946;
  background-image: url('images/background.png');
  background-size: 100%;
  background-attachment:fixed;
}
.timber{
  color: #46B65E;
  font-family: 'Inter' !important;

}
.txt{
  color: #255946;
}

@media (min-width: 992px) {
  .textalign{
    text-align: justify;
    width: 75%;
    margin: auto;
  }
  .textalign1{
    text-align: justify;
    /* width: 75%;
    margin: auto; */
  }

}